import * as Registration from "./registration";

export enum TransactionStatus {
    PAID = 'PAID',
    WAITING = 'WAITING',
    EXPIRED = 'EXPIRED',
}

export class TransactionItem {
    id: string = '';
    name: string = '';
    qty: number = 0;
    price: number = 0;
    notes: string = '';
}

export class Transaction {
    bill_no: string = '';
    trx_id?: string = '';
    status: TransactionStatus = TransactionStatus.WAITING;
    bill_at: string = '';
    expired_at: string = '';
    paid_at?: string;
    bill_total: number = 0;
    bill_description: string = '';
    payment_channel_id?: string;
    payment_channel_name?: string;
    cf_payment_receipt_id?: string;
    items: TransactionItem[] = [];
    payment_url: string = '';
}

export class TransactionPayment {
    bill_at: string = '';
    bill_no: string = '';
    bill_total: number = 0;
    cell_phone: string = '';
    created_at: string = '';
    expired_at: string = '';
    customer_id: string = '';
    email: string = '';
    gross_total: string = '';
    name: string = '';
    paid_at: string = '';
    payment_channel_id: string = '';
    payment_channel_name: string = '';
    status: TransactionStatus = TransactionStatus.WAITING;
    unique_code: number = 0;
}

export type TransactionData<T> = Registration.Transaction & T & {
    registration_fee?: string;
    payment_channel_name?: string;
    bill_at?: string;
    paid_at?: string;
    expired_at?: string;
    pick_up_url?: string;
};

export const ticketPrice = 40000;