import { create } from 'zustand';
import { Child, DataRegistration, PayloadGroup, PayloadIndividual, PayloadPartnership } from '../constants/registration';

type RegistrationStore = {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;

    dataRegistration?: DataRegistration[];
    setDataRegistration: (payload: DataRegistration[] | ((prev: DataRegistration[]) => DataRegistration[])) => void;

    payloadPartnership?: PayloadPartnership,
    setPayloadPartnership: (payloadPartnership: PayloadPartnership) => void,

    parnetshipPaymentImage?: File;
    setPartnershipPaymentImage: (file: File) => void;

    payloadIndividual?: PayloadIndividual,
    setPayloadIndividual: (payloadIndividual: PayloadIndividual) => void,

    payloadGroup?: PayloadGroup,
    setPayloadGroup: (payloadGroup: PayloadGroup) => void,

    payloadChildren: Child[],
    setPayloadChildren: (payloadChildren: Child[]) => void,

    clearAllPayload: () => void;
}

export const useRegistration = create<RegistrationStore>((set) => ({
    isLoading: false,
    setIsLoading: (isLoading) => set(() => ({
        isLoading
    })),

    dataRegistration: undefined,
    setDataRegistration: data => set(state => ({
        dataRegistration: typeof data === 'function' ? data(state.dataRegistration ?? {} as DataRegistration[]) : data,
    })),

    payloadPartnership: undefined,
    setPayloadPartnership: (payloadPartnership) => set(() => ({
        payloadPartnership,
    })),

    parnetshipPaymentImage: undefined,
    setPartnershipPaymentImage: (file) => set(() => ({
        parnetshipPaymentImage: file
    })),

    payloadIndividual: undefined,
    setPayloadIndividual: (payloadIndividual: PayloadIndividual) => set(() => ({
        payloadIndividual
    })),

    payloadGroup: undefined,
    setPayloadGroup: (payloadGroup: PayloadGroup) => set(() => ({
        payloadGroup
    })),

    payloadChildren: [new Child()],
    setPayloadChildren: (payloadChildren) => set(() => ({
        payloadChildren
    })),

    clearAllPayload: () => set(() => ({
        payloadPartnership: undefined,
        parnetshipPaymentImage: undefined,
        payloadIndividual: undefined,
        payloadGroup: undefined,
        payloadChildren: [new Child()]
    }))
}));