import axios from 'axios';
import { useAuth } from '../stores/auth';
import { Child, Group, Partnership, PARTNERSHIP_CATEGORY, PayloadGroup, PayloadIndividual, PayloadPartnership, RegistrationHistoryList } from '../constants/registration';

const baseUrl = 'https://api-bible-study-jkt.gms.church';

export const getAllRegistration = async () => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response?.data;
    } catch (err) {
        return null;
    }
}

export const getAllIndividualRegistration = async (): Promise<Child[] | null> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/individual/children`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response?.data?.result;
    } catch (err) {
        return null;
    }
}

export const getAllGroupRegistration = async (): Promise<(Child & { school: Group })[] | null> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/school/student`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response?.data?.result;
    } catch (err) {
        return null;
    }
}

export const getAllPartnershipRegistration = async (): Promise<Partnership[] | null> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/partnership`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response?.data?.result;
    } catch (err) {
        return null;
    }
}

export const getRegistrationByPersonId = async (personId: string): Promise<RegistrationHistoryList | null> => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/registration/${personId}`
        });
        return response?.data?.result;
    } catch (err) {
        return null;
    }
}

export const getRegistrationByBillNo = async (params: { billNo?: string, regNo?: string, singleSnapshot?: boolean }) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data`,
            params: {
                'bill-no': params.billNo,
                'registration-number': params.regNo
            }
        });
        const result = response?.data?.result;
        if (!result) throw response;

        if (!params.singleSnapshot) return result;
        
        if (result?.parent?.length) {
            return result.parent[result.parent.length - 1];
        } else if (result?.school?.length) {
            return result.school[result.school.length - 1];
        } else if (result?.partnership?.length) {
            return result.partnership[result.partnership.length - 1];
        }

        throw response;
    } catch (err) {
        return null;
    }
}

export const postRegistrationIndividual = async (payload: PayloadIndividual, personId: string, billNo: string, redirectUrl: string) => {
    try {
        const { parent_domicile, parent_domicile_other, ...data } = payload;
        const response = await axios({
            method: 'post',
            url: `${baseUrl}/registration`,
            data: {
                ...data,
                parent_domicile: parent_domicile?.toLowerCase?.() === 'others'
                    ? parent_domicile_other
                    : parent_domicile,
                person_id: personId,
                bill_no: billNo,
                redirect_url: redirectUrl
            }
        });
        if (response?.data?.result) {
            return response.data.result;
        }
        throw null;
    } catch (err) {
        throw err;
    }
}

export const postRegistrationGroup = async (payload: PayloadGroup, personId: string) => {
    try {
        const { school_domicile, school_domicile_other, ...data } = payload;
        const response = await axios({
            method: 'post',
            url: `${baseUrl}/registration/school`,
            data: {
                ...data,
                school_domicile: school_domicile?.toLowerCase?.() === 'others'
                    ? school_domicile_other
                    : school_domicile,
                person_id: personId,
            }
        });
        if (response?.data?.registration_number) {
            return response.data;
        }
        throw null;
    } catch (err) {
        throw err;
    }
}

export const postRegistrationPartnership = async (data: PayloadPartnership, personId: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${baseUrl}/registration/partnership`,
            data: {
                ...data,
                person_id: personId,
                sponsored_children_count: data.sponsored_children_count || '0',
                number_plate: data.partnership_category === PARTNERSHIP_CATEGORY.DRIVER
                    ? data.number_plate ? data.number_plate.replace(/ /g,'') : ''
                    : undefined
            }
        });
        if (response?.data) {
            return response.data;
        }
        throw null;
    } catch (err) {
        throw err;
    }
}

export const postPartnershipPaymentConfirmation = async (id: string, data: FormData) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${baseUrl}/registration/partnership/${id}/payment-confirmation`,
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response?.data) {
            return response.data;
        }
        throw response;
    } catch (err) {
        throw err;
    }
}

export const putRegistrationChildren = async (idChild: string, data: Partial<Child>) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${baseUrl}/registration/children/${idChild}`,
            data
        });
        if (response?.data) {
            return response.data;
        }
        throw null;
    } catch (err) {
        throw err;
    }
}