import { Profile } from "constants/auth";
import { create } from "zustand";

type AuthStore = {
    authCode?: string;
    setAuthCode: (authCode?: string) => void;

    profile?: Profile;
    setProfile: (profile: Profile) => void;

    token?: string;
    setToken: (token?: string) => void;
}

export const useAuth = create<AuthStore>((set) => ({
    authCode: undefined,
    setAuthCode: (authCode) => set(() => ({
        authCode,
    })),

    profile: undefined,
    setProfile: (profile) => set(() => ({
        profile,
    })),

    token: undefined,
    setToken: (token) => set(() => ({
        token,
    })),
}));