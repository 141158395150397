import React from 'react';

type PaymentInformationGroupProps = {
    className?: string;
}

export const PaymentInformationGroup = (props: PaymentInformationGroupProps) => (
    <div className={`preview_info ${props.className ?? ''}`}>
        <span className='mb-3 text-justify'>
        Mohon menunggu kami akan menghubungi Anda untuk informasi lebih lanjut, atau Anda dapat menghubungi kami melalui:
        </span>

        <span className='mb-2'>
            Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)
        </span>

        <span className='mb-3'>
            IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang
        </span>

        <span>Terimakasih.</span>
        <span>Tuhan memberkati.</span>
    </div>
)