import React from 'react';

export const PaymentInformationDana = (props: { className?: string }) => (
    <div className={`preview_info ${props.className ?? ''}`}>
        <span className='mb-3 text-justify'>
            Pembayaran Anda terverifikasi maksimal 1x24 jam hari kerja setelah pembayaran dilakukan, dan akan menerima notifikasi melalui aplikasi GMS Church.
            <br />
        </span>

        <span className='mb-3 text-justify'>
            Apabila dalam 1 hari kerja Anda belum menerima email verifikasi, Anda dapat menghubungi kami melalui: 
        </span>

        <span className='mb-2'>
            Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)
        </span>

        <span className='mb-3'>
            IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang / @gmsbekasi / @gmsbandung / @gmscirebon / @gmssukabumi
        </span>

        <span>Terimakasih.</span>
        <span>Tuhan memberkati.</span>
    </div>
)