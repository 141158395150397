import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../stores/auth';
import { getProfile } from '../datas/auth';
import { getTransactionByPersonId } from '../datas/transaction';
import { useTransaction } from 'stores/transaction';
import { getRegistrationByPersonId } from 'datas/registration';
import { useRegistration } from 'stores/registration';
import Navbar from 'components/molecules/navbar';

const AuthProvider = (props: React.PropsWithChildren) => {
    const transactionAutoReloadInterval = 30000;
    
    const [params] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const transaction = useTransaction().transaction;
    const setDataRegistration = useRegistration(state => state.setDataRegistration);

    const handleGetTransactionByInterval = async () => {
        const personId = auth.profile?.person_id;

        if (personId) {
            await getTransactionByPersonId(personId);
        }
    }

    const getDataRegistration = async (personId: string) => {
        try {
            if (!personId) {
                throw new Error('Person ID not found.');
            }
    
            const data = await getRegistrationByPersonId(personId);
            if (data) {
                const dataRegistration = Object.entries(data).flatMap(([key, items]) =>
                    items.map((item: any) => ({
                        ...item,
                        category: key
                    }))
                );
                setDataRegistration(dataRegistration);
                if (dataRegistration?.length) {
                    return dataRegistration;
                }
            }
            
            throw new Error('Data not found');
        } catch (err) {
            return [];
        }
    }

    const setupCredentials = async (authCode: string) => {
        auth.setAuthCode(authCode);
        const profile = await getProfile(authCode);

        if (profile?.person_id) {
            const isReportScreen = location.pathname.includes('/report');
            if (!isReportScreen) {
                await getTransactionByPersonId(profile.person_id);

                const dataRegistration = await getDataRegistration(profile.person_id);
                if (dataRegistration.length) {
                    navigate('/transaction');
                    return;
                }
            } else {
                return;
            }
        }

        navigate('/');
    }

    useEffect(() => {
        const authCode = params.get('code') ?? auth.authCode;
        if (authCode) {
            setupCredentials(authCode);
        } else {
            let pathname = location.pathname ? location.pathname.replace(/\/$/, '') : location.pathname;
            const queries = window.location.search;
            if (queries) pathname += queries;

            window.location.href = `https://accounts.gms.church/oauth/authorize?scope=profile,contact&state=state&client_id=19df3cd21b8102caad0880fabcc6a1da&redirect_uri=${process.env.REACT_APP_BASE_URL}${pathname}`;
        }
    }, [])

    useEffect(() => {
        if (auth.profile?.person_id) {
            const autoReloadInterval = setInterval(
                handleGetTransactionByInterval,
                transactionAutoReloadInterval
            );
    
            return () => {
                clearInterval(autoReloadInterval);
            };
        }
    }, [auth.profile?.person_id])

    const isAdmin = useMemo<boolean>(() => {
        return location.pathname.includes('/report');
    }, [location.pathname])

    return (
        <>
            {!location.pathname?.includes('/report') && (
                <Navbar />
            )}
            {transaction || isAdmin ? (
                    props.children
                ) : (
                    <div className='d-flex align-items-center justify-content-center vw-100 vh-100'>
                        {transaction !== null ? (
                            <img className='loader' src='/android-icon-192x192.png' style={{ borderRadius: '0.5rem' }} />
                        ) : <p>Error</p>}
                    </div>
                )}
        </>
    )
}

export default AuthProvider;