import axios from 'axios';
import { useTransaction } from '../stores/transaction';
import { useAuth } from '../stores/auth';
import { PayloadGroup, PayloadIndividual, PayloadPartnership, PayloadTransaction, Transaction, eventPeriod, payment_branch } from '../constants/registration';
import { formatAmount } from 'utils/format';

const baseUrl = 'https://api-bible-study-jkt.gms.church';
const pushNotifToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZXMiOlsibXlnbXMiXSwidGFncyI6WyJiaWJsZS1zdHVkeS1qamIiXSwiaWF0IjoxNzA5NzgzNDY4fQ.b-25nFZmsJ80MK1sjz8RgyLYgkXuimUQjPh3G_rjkVA';

export type TransactionItem = {
    item_id: string;
    qty: number;
    notes: string;
}

export type TransactionNotification = {
    registrationNumber: string;
    redirectUrl?: string;
    nominal?: number;
    eventLocation?: string;
}

export const getAllTransaction = async () => {
    const setAllTransaction = useTransaction.getState().setAllTransaction;
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/individual`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const data = response?.data?.result as PayloadTransaction[];
        if (data?.length) {
            const sortedData = data.reverse();
            setAllTransaction(sortedData);
            return sortedData;
        }
        throw data;
    } catch (err) {
        setAllTransaction([]);
        return [];
    }
}

export const getIndividualTransaction = async (page: number, limit?: number): Promise<(Transaction & PayloadIndividual)[]> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/individual`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                limit
            },
        });
        const data = response?.data?.result?.data as (Transaction & PayloadIndividual)[];
        if (data?.length) return data;
        throw data;
    } catch (err) {
        return [];
    }
}

export const getGroupTransaction = async (): Promise<(Transaction & PayloadGroup)[]> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/school`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const data = response?.data?.result as (Transaction & PayloadGroup)[];
        if (data?.length) {
            const sortedData = data.reverse();
            return sortedData;
        }
        throw data;
    } catch (err) {
        return []
    }
}

export const getPartnershipTransaction = async (): Promise<(Transaction & PayloadPartnership)[]> => {
    try {
        const token = useAuth.getState().token;
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/data/partnership`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const data = response?.data?.result as (Transaction & PayloadPartnership)[];
        if (data?.length) {
            const sortedData = data.reverse();
            return sortedData;
        }
        throw data;
    } catch (err) {
        return []
    }
}

export const getAllPaymentTransaction = async (page: number, limit?: number) => {
    const token = useAuth.getState().token;
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/events/transactions`,
            params: {
                page,
                limit
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const data = response?.data?.result?.result;
        if (data?.length) {
            return data;
        }
        throw data;
    } catch (err) {
        return [];
    }
}

export const getTransactionByPersonId = async (personId: string, withNoSetter?: boolean) => {
    const setTransaction = useTransaction.getState().setTransaction;
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/transactions/${personId}/details`
        });
        const result = response?.data?.result?.result;
        if (result) {
            const data = result?.length ? result : [];
            const activeData = data.filter((trx: any) => {
                const date = trx.paid_at || trx.expired_at || trx.bill_at;
                return date.includes(eventPeriod);
            })
            if (!withNoSetter) setTransaction(activeData);
            return activeData;
        }
        throw response;
    } catch (err) {
        setTransaction([]);
        return [];
    }
}

export const postTransaction = async (personId: string, items: TransactionItem[]): Promise<{ bill_no: string, redirect_url: string }> => {
    try {
        const response = await axios({
            method: 'post',
            url: `${baseUrl}/transactions/${personId}`,
            data: {
                description: 'Pembelian Tiket Kids Camp Jabodetabek',
                items,
                redirect_url: `${process.env.REACT_APP_BASE_URL}/?auth_code=${personId}`,
            }
        });
        const result = response?.data?.result?.result;
        if (result?.bill_no) {
            return result;
        }
        throw null;
    } catch (err) {
        throw err;
    }
}

export const postTransactionNotification = async (personId: string, message: string, content: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: 'https://api.gms.church/v3/notification/send-to-user',
            data: {
                person_id: personId,
                title: 'Bible Study 2024',
                message,
                tag: 'bible-study-jjb',
                content
            },
            headers: {
                Authorization: `Bearer ${pushNotifToken}`,
            }
        });
        return response;
    } catch (err) {
        return null;
    }
}

export const postRegistrationTransactionNotification = (attribute: TransactionNotification) => {
    try {
        const profile = useAuth.getState().profile;
        const message = `<div style='background-color: #fff; padding-left: 16px; padding-right: 16px; padding-bottom: 16px; border-radius: 4px;font-size: 16px; text-align: justify;'>
            <p>Shalom,</p>
            <p>Terima kasih atas pendaftaran Anda di <b>Bible Study 2024</b>.</p>
            <div style='width: 100%; padding: 16px; background-color: #dedede; border-radius: 4px;'>
                <span style='display: block; font-size: 14px; margin-bottom: 2px;'>No Registrasi Anda:</span>
                <span style='display: block; font-size: 16px;'><b>${attribute.registrationNumber}</b></span>
            </div>
            <p>Silakan melakukan pembayaran sebesar <b>${formatAmount(attribute.nominal ?? 0)}</b> ke:</p>
            <p style='text-align: center'><b>Transaction Fastpay</b><br/><b>( OVO, Dana, Shoppe Pay App, BCA Virtual Account Online, dll)</b></p>
            <p>Pembayaran Anda terverifikasi maksimal 1x24 jam hari kerja setelah pembayaran dilakukan, dan akan menerima notifikasi melalui aplikasi GMS Church.</p>
            <p>Apabila dalam 1 hari kerja Anda belum menerima email verifikasi, Anda dapat menghubungi kami melalui:</p>
            <p>Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)</p>
            <p style='text-align: left;'>IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang</p>
            <p>Terimakasih.<br>Tuhan memberkati.</p>
            <a style='display: block; width: 100%; padding: 16px 24px; background-color: #364950; border-radius: 4px; text-align: center; color: #fff; text-decoration: none;' href='${attribute.redirectUrl ?? ''}&redirect_uri=${process.env.REACT_APP_BASE_URL}'>Lanjutkan Pembayaran</a>
        </div>`;

        postTransactionNotification(
            profile!.person_id,
            'Pendaftaran Anda di Bible Study 2024 telah berhasil.',
            message
        );
    } catch (err) {
        return null;
    }
}

export const postGroupRegistrationTransactionNotification = (attribute: TransactionNotification & PayloadGroup) => {
    try {
        const profile = useAuth.getState().profile;
        const message = `<div style='background-color: #fff; padding-left: 16px; padding-right: 16px; padding-bottom: 8px; border-radius: 4px;font-size: 16px; text-align: justify;'>
            <p>Shalom,</p>
            <p>Terima kasih atas pendaftaran Anda di <b>Bible Study 2024</b>.</p>
            <div style='width: 100%; padding: 16px; background-color: #dedede; border-radius: 4px;'>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>No Registrasi Anda:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.registrationNumber}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>Nama Sekolah/Rombongan:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.school_name}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>Nama Pendamping/Guru:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.teacher_name}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>No Handphone Pendamping:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.teacher_phone}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>Jumlah Anak yang didaftarkan:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.student_count}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>Domisili Sekolah:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.school_domicile_other ? attribute.school_domicile_other : attribute.school_domicile}</b></span>
                </div>
                <div style='margin-bottom: 8px;'>
                    <span style='display: block; font-size: 14px; margin-bottom: 2px;'>Akan mengikuti Bible Study di:</span>
                    <span style='display: block; font-size: 16px;'><b>${attribute.event_location}</b></span>
                </div>
            </div>
            <p>Mohon menunggu kami akan menghubungi Anda untuk informasi lebih lanjut, atau Anda dapat menghubungi kami melalui:</p>
            <p>Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)</p>
            <p style='text-align: left;'>IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang</p>
            <p>Terimakasih.<br>Tuhan memberkati.</p>
        </div>`;

        postTransactionNotification(
            profile!.person_id,
            'Pendaftaran Anda di Bible Study 2024 telah berhasil.',
            message
        );
    } catch (err) {
        return null;
    }
}

export const postPartnershipDanaTransactionNotification = (attribute: TransactionNotification) => {
    try {
        const profile = useAuth.getState().profile;
        const message = `<div style='background-color: #fff; padding-left: 16px; padding-right: 16px; padding-bottom: 8px; border-radius: 4px;font-size: 16px; text-align: justify;'>
            <p>Shalom,</p>
            <p>Pembayaran partnership Anda di Bible Study 2024 dengan No. Registrasi <b>${attribute.registrationNumber}</b> sejumlah ${attribute.nominal ? formatAmount(attribute.nominal) : ''} telah berhasil.</p>
            <p>Terimakasih.<br>Tuhan memberkati.</p>
        </div>`;

        postTransactionNotification(
            profile!.person_id,
            'Pendaftaran Partnership Anda di Bible Study 2024 telah berhasil.',
            message
        );
    } catch (err) {
        return null;
    }
}

export const postPartnershipDriverTransactionNotification = (attribute: TransactionNotification) => {
    try {
        const profile = useAuth.getState().profile;
        const message = `<div style='background-color: #fff; padding-left: 16px; padding-right: 16px; padding-bottom: 8px; border-radius: 4px;font-size: 16px; text-align: justify;'>
            <p>Shalom,</p>
            <p>Terima kasih atas pendaftaran partnership Anda di Bible Study 2024</b>.</p>
            <div style='width: 100%; padding: 16px; background-color: #dedede; border-radius: 4px;'>
                <span style='display: block; font-size: 14px; margin-bottom: 2px;'>No Registrasi Anda:</span>
                <span style='display: block; font-size: 16px;'><b>${attribute.registrationNumber}</b></span>
            </div>
            <p>Mohon menunggu kami akan menghubungi Anda untuk informasi lebih lanjut, atau Anda dapat menghubungi kami melalui:</p>
            <p>Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)</p>
            <p style='text-align: left;'>IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang</p>
            <p>Terimakasih.<br>Tuhan memberkati.</p>
        </div>`;

        postTransactionNotification(
            profile!.person_id,
            'Pendaftaran Partnership Anda di Bible Study 2024 telah berhasil.',
            message
        );
    } catch (err) {
        return null;
    }
}