import { create } from 'zustand';
import { Transaction, TransactionData, TransactionPayment } from 'constants/transaction';
import { PayloadGroup, PayloadIndividual, PayloadPartnership, PayloadTransaction } from 'constants/registration';

type TransactionStore = {
    allTransaction?: PayloadTransaction[],
    setAllTransaction: (transactions: PayloadTransaction[]) => void;

    allPaymentTransaction: TransactionPayment[];
    setAllPaymentTransaction: (transaction: TransactionPayment[]) => void;

    transaction?: Transaction[],
    setTransaction: (transactions: Transaction[]) => void;
    addTransaction: (transaction: Transaction) => void;

    individualTransaction?: TransactionData<PayloadIndividual>[];
    setIndividualTransaction: (transaction: TransactionData<PayloadIndividual>[]) => void;

    groupTransaction?: TransactionData<PayloadGroup>[];
    setGroupTransaction: (transaction: TransactionData<PayloadGroup>[]) => void;

    partnershipTransaction?: TransactionData<PayloadPartnership>[];
    setPartnershipTransaction: (transaction: TransactionData<PayloadPartnership>[]) => void;
}

export const useTransaction = create<TransactionStore>((set) => ({
    allTransaction: undefined,
    setAllTransaction: (transactions) => set(() => ({
        allTransaction: transactions,
    })),
    allPaymentTransaction: [],
    setAllPaymentTransaction: (payment: TransactionPayment[]) => set(() => ({
        allPaymentTransaction: payment,
    })),
    transaction: undefined,
    setTransaction: (transactions) => set(() => ({
        transaction: transactions,
    })),
    addTransaction: (transaction) => set(prevs => ({
        transaction: (prevs.transaction ?? []).concat([transaction]),
    })),
    individualTransaction: undefined,
    setIndividualTransaction: (transaction) => set(() => ({
        individualTransaction: transaction
    })),
    groupTransaction: undefined,
    setGroupTransaction: (transaction) => set(() => ({
        groupTransaction: transaction
    })),

    partnershipTransaction: undefined,
    setPartnershipTransaction: (transaction) => set(() => ({
        partnershipTransaction: transaction
    })),
}));