const CryptoJS = require("crypto-js");

const chiperKey = 'ybDG5jutPYfpk3BOOE8IP5rF1BYnCZVyVxB75rZwaUcoQO1o7elEfYqJS9QqFmynh604YkZN5nHPIra';

export const encrypt = (text: string) => {
    const ciphertext = CryptoJS.AES.encrypt(
        text,
        chiperKey
    ).toString();
    return ciphertext;
}

export const decrypt = (text: string) => {
    const bytes = CryptoJS.AES.decrypt(
        text,
        chiperKey
    );
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

export const hash = (text: string) => {
    const hashed = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(text));
    return hashed.toString();
}