import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import FormRequired from 'components/atoms/form-required';
import { useRegistration } from 'stores/registration';
import * as Constant from 'constants/registration';
import { useAuth } from 'stores/auth';

export const FormPartnership = () => {
    const profile = useAuth().profile;
    const { payloadPartnership: payload, setPayloadPartnership: setPayload } = useRegistration();

    const onChange = (key: keyof Constant.PayloadPartnership, value: Constant.PARTNERSHIP_CATEGORY | string) => {
        const newPayload = payload ? {...payload} : {} as Constant.PayloadPartnership;
        newPayload[key] = value as never;
        setPayload(newPayload);
    }

    useEffect(() => {
        if (profile?.person_id) {
            const newPayload = {...payload};
            newPayload.name = profile.name || '';
            newPayload.phone = profile.phone || '';
            setPayload(newPayload as unknown as Constant.PayloadPartnership);
        }
    }, [])

    return (
        <Form.Group>
            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Nama lengkap <FormRequired /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.name ?? ''}
                        onChange={(e) => onChange('name', e.target.value?.toUpperCase?.())}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>No Handphone Jemaat <FormRequired /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.phone ?? ''}
                        onChange={(e) => onChange('phone', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Partnership yang diberikan <FormRequired /></Card.Text>
                    <Form.Select
                        required
                        value={payload?.partnership_category ?? ''}
                        onChange={(e) => onChange('partnership_category', e.target.value)}
                    >
                        <option hidden value=''>Pilih kategori</option>
                        {Constant.partnershipCategory.map(option => (
                            <option value={option.value} key={`partnership_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            {payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER ? (
                <>
                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Alamat Domisili <FormRequired /></Card.Text>
                            <Form.Control
                                required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER}
                                value={payload?.domicile ?? ''}
                                onChange={(e) => onChange('domicile', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Plat Nomor <FormRequired /></Card.Text>
                            <Form.Control
                                required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER}
                                value={payload?.number_plate ?? ''}
                                onChange={(e) => onChange('number_plate', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Jenis Mobil <FormRequired /></Card.Text>
                            <Form.Control
                                required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER}
                                value={payload?.car_type ?? ''}
                                onChange={(e) => onChange('car_type', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Warna Mobil <FormRequired /></Card.Text>
                            <Form.Control
                                required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER}
                                value={payload?.car_color ?? ''}
                                onChange={(e) => onChange('car_color', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Muatan <FormRequired /></Card.Text>
                            <Form.Control
                                type='number'
                                required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER}
                                value={payload?.car_load ?? ''}
                                onChange={(e) => onChange('car_load', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>
                </>
            ) : payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DANA && (
                <Card className='mb-2'>
                    <Card.Body>
                        <Card.Text>Jumlah Anak yang Disponsori <FormRequired /></Card.Text>
                        <Form.Control
                            required={payload?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DANA}
                            type='number'
                            value={payload?.sponsored_children_count ?? ''}
                            onChange={(e) => onChange('sponsored_children_count', e.target.value)}
                        />
                        <span className="separator" />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Card.Body>
                </Card>
            )}

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Gereja Lokal <FormRequired /></Card.Text>
                    <Form.Select
                        required
                        value={payload?.event_location ?? ''}
                        onChange={(e) => onChange('event_location', e.target.value)}
                    >
                        <option hidden value=''>Pilih gereja lokal</option>
                        {Constant.event_location.map(option => (
                            <option value={option.value} key={`location_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>
        </Form.Group>
    )
}