import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PreviewIndividual from 'components/organisms/previews/preview-individual';
import PreviewGroup from 'components/organisms/previews/preview-group';
import PreviewPartnership from 'components/organisms/previews/preview-partnership';

export const TransactionDetail = () => {
    const params = useParams();
    const [registrationNumber, setRegistrationNumber] = useState<string | null>('');

    const getRegistrationNumber = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const queries = {} as any;

        for (let [key, value] of searchParams.entries()) {
            queries[key] = value;
        }

        if (queries.reg) {
            setRegistrationNumber(queries.reg);
        } else {
            setRegistrationNumber(null);
        }
    }

    useEffect(() => {
        getRegistrationNumber();
    }, [])

    return registrationNumber ? (
        <section className='transaction_detail_container'>
            {params?.category === 'parent' ? (
                <PreviewIndividual billNo={registrationNumber} />
            ) : params?.category === 'school' ? (
                <PreviewGroup registrationNumber={registrationNumber} />
            ) : params?.category === 'partnership' ? (
                <PreviewPartnership registrationNumber={registrationNumber} />
            ) : null}
        </section>
    ) : null;
}