import React, { useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import { CSVLink } from 'react-csv';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ticketPrice, TransactionStatus } from 'constants/transaction';
import { event_location, payment_status, PARTNERSHIP_CATEGORY, Partnership } from 'constants/registration';
import ReportPartnershipExpanded from '../report-partnership-expanded';
import { formatDate } from 'utils/format';
import { getAllPartnershipRegistration } from 'datas/registration';

enum PaymentStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID'
}

type Data = Partnership & { nominal: number, payment_status?: PaymentStatus };

export const ReportPartnership = () => {
    const dataAutoReloadInterval = 30000;

    const [dataRegistration, setDataRegistration] = useState<Data[]>();
    const [filterName, setFilterName] = useState('');
    const [filterEventLocation, setFilterEventLocation] = useState('');
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<TransactionStatus>();

    const headers = [
        { label: 'No Registrasi', key: 'registration_number' },
        { label: 'Nama Lengkap Jemaat', key: 'name' },
        { label: 'No Handphone Jemaat', key: 'phone' },
        { label: 'Pertnership yang Diberikan', key: 'partnership_category' },
        { label: 'Jumlah Anak yang Disponsori', key: 'sponsored_children_count' },
        { label: 'Nominal Dana', key: 'nominal' },
        { label: 'Status Pembayaran', key: 'payment_status' },
        { label: 'Plat Nomor', key: 'number_plate' },
        { label: 'Jenis Mobil', key: 'car_type' },
        { label: 'Warna Mobil', key: 'car_color' },
        { label: 'Muatan', key: 'car_load' },
        { label: 'Gereja Lokal', key: 'event_location' },
        { label: 'Alamat Domisili', key: 'domicile' },
        { label: 'Tanggal Registrasi', key: 'created_at' },
    ];

    const danaOnlyHeaders = [
        { label: 'No Registrasi', key: 'registration_number' },
        { label: 'Nama Lengkap Jemaat', key: 'name' },
        { label: 'No Handphone Jemaat', key: 'phone' },
        { label: 'Pertnership yang Diberikan', key: 'partnership_category' },
        { label: 'Jumlah Anak yang Disponsori', key: 'sponsored_children_count' },
        { label: 'Nominal Dana', key: 'nominal' },
        { label: 'Gereja Lokal', key: 'event_location' },
        { label: 'Status Pembayaran', key: 'payment_status' },
        { label: 'Tanggal Registrasi', key: 'created_at' },
    ];
    
    const driverOnlyHeaders = [
        { label: 'No Registrasi', key: 'registration_number' },
        { label: 'Nama Lengkap Jemaat', key: 'name' },
        { label: 'No Handphone Jemaat', key: 'phone' },
        { label: 'Pertnership yang Diberikan', key: 'partnership_category' },
        { label: 'Plat Nomor', key: 'number_plate' },
        { label: 'Jenis Mobil', key: 'car_type' },
        { label: 'Warna Mobil', key: 'car_color' },
        { label: 'Muatan', key: 'car_load' },
        { label: 'Gereja Lokal', key: 'event_location' },
        { label: 'Alamat Domisili', key: 'domicile' },
        { label: 'Tanggal Registrasi', key: 'created_at' },
    ];

    const columns: TableColumn<Data>[] = [
        {
            id: 'registration_number',
            name: 'No Registrasi',
            selector: row => row?.registration_number || '',
            sortable: true,
            width: '150px'
        },
        {
            id: 'name',
            name: 'Nama Lengkap Jemaat',
            selector: row => row?.name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'event_location',
            name: 'Gereja Lokal',
            selector: row => row?.event_location ?? '-',
            sortable: true,
        },
        {
            id: 'partnership_category',
            name: 'Pertnership yang Diberikan',
            selector: row => row?.partnership_category as PARTNERSHIP_CATEGORY,
            sortable: true,
            conditionalCellStyles: [
                { when: (row) => row?.partnership_category === PARTNERSHIP_CATEGORY.DANA, classNames: ['pick_up_type_success'] },
                { when: (row) => row?.partnership_category === PARTNERSHIP_CATEGORY.DRIVER, classNames: ['pick_up_type_warning'] }
            ]
        },
        {
            id: 'created_at',
            name: 'Tanggal Registrasi',
            selector: row => row?.created_at ? formatDate(row?.created_at) : '',
            sortable: true,
        }
    ];

    const handleLoadDataRegistration = async () => {
        try {
            const response = await getAllPartnershipRegistration();
            if (response) {
                const items = response.map(item => ({
                    ...item,
                    nominal: item.sponsored_children_count * ticketPrice,
                    payment_status: item.partnership_category === PARTNERSHIP_CATEGORY.DANA
                        ? item.payment_confirmation ? PaymentStatus.PAID : PaymentStatus.UNPAID
                        : undefined
                }));
                setDataRegistration(items);
                return true;
            }

            throw response;
        } catch (err) {
        }
    }

    const data = useMemo<Data[]>(() => {
        if (!dataRegistration) return [];
    
        const matchesFilters = (item: Data) => {
            const { name, registration_number } = item;
    
            if (filterName) {
                const regNoMatch = registration_number?.toUpperCase().includes(filterName.toUpperCase());
                const nameMatch = name?.toLowerCase().includes(filterName.toLowerCase());
                if (!regNoMatch && !nameMatch) return false;
            }
    
            return true;
        };
    
        return dataRegistration
            .filter(matchesFilters);
    }, [dataRegistration, filterEventLocation, filterName]); 

    useEffect(() => {
        handleLoadDataRegistration();
        const autoReloadInterval = setInterval(
            handleLoadDataRegistration,
            dataAutoReloadInterval
        );

        return () => {
            clearInterval(autoReloadInterval);
        }
    }, [])

    return data ? (
        <div className="report_container_content">
            <div className='report_filter'>
                <Form.Control
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder='Cari Nama Jemaat atau No Registrasi'
                />
                <Form.Select
                    value={filterEventLocation}
                    onChange={(e) => {
                        setFilterEventLocation(e.target.value)
                    }}
                >
                    <option value=''>Cari Gereja Lokal</option>
                    {event_location.map((location) => (
                        <option value={location.value} key={`option_event_location_${location.value}`}>{location.label}</option>
                    ))}
                </Form.Select>
                <Form.Select
                    value={filterPaymentStatus}
                    onChange={(e) => {
                        setFilterPaymentStatus(e.target.value as TransactionStatus)
                    }}
                >
                    <option value=''>Status Pembayaran</option>
                    {payment_status.map((status) => (
                        <option value={status.value} key={`option_payment_status_${status.value}`}>{status.label}</option>
                    ))}
                </Form.Select>
                
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Export Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.ItemText>
                            <CSVLink
                                data={data}
                                headers={headers}
                                filename='transaction_bible_study_report'
                                target="_blank"
                            />
                            Data Partnership
                        </Dropdown.ItemText>
                        <Dropdown.ItemText>
                            <CSVLink
                                data={data.filter(({ partnership_category }) => partnership_category === PARTNERSHIP_CATEGORY.DANA)}
                                headers={danaOnlyHeaders}
                                filename='transaction_bible_study_report'
                                target="_blank"
                            />
                            Data Partnership (Dana Only)
                        </Dropdown.ItemText>
                        <Dropdown.ItemText>
                            <CSVLink
                                data={data.filter(({ partnership_category }) => partnership_category === PARTNERSHIP_CATEGORY.DRIVER)}
                                headers={driverOnlyHeaders}
                                filename='transaction_bible_study_report'
                                target="_blank"
                            />
                            Data Partnership (Driver Only)
                        </Dropdown.ItemText>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <DataTable
                keyField='id'
                data={data}
                columns={columns}
                pagination
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={ReportPartnershipExpanded}
            />
        </div>
    ) : (
        <div className='d-flex align-items-center justify-content-center' style={{ height: 'calc(100vh - 10rem)' }}>
            <Spinner />
        </div>
    )
}