import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormCheck from 'components/atoms/form-check';
import Required from 'components/atoms/form-required';
import * as Constant from 'constants/registration';

interface FormChildrenProps {
    data?: Constant.Child[];
    isOutsiteOfChurch?: boolean;
    onChange: <K extends keyof Constant.Child>(
        key: number,
        keyField: K,
        value: Constant.Child[K]
    ) => void;
    onChangeRow: (prop: {
        action: 'add' | 'remove',
        removedId?: number,
        children?: Constant.Child,
    }) => void;
}

export const FormChildren = (props: FormChildrenProps) => {
    const { data, onChange, onChangeRow } = props;
    return (
        <>
            {data?.map((child, index) => (
                <React.Fragment key={`child-form-${index}`}>
                    {data.length > 1 ? (
                        <div className='child_form_label'>
                            <span className='fw-bolder'>Anak {Constant.numberDescriptions[index + 1]}</span>
                            <button
                                onClick={() => onChangeRow({
                                    action: 'remove',
                                    removedId: index,
                                })}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                                    <path stroke="none" d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
                                </svg>
                            </button>
                        </div>
                    ) : null}

                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Text>Nama Lengkap Anak  <Required /></Card.Text>
                            <Form.Control
                                required
                                value={child.name ?? ''}
                                onChange={(e) => onChange(index, 'name', e.target.value?.toUpperCase?.())}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <FormCheck
                                required
                                label='Kelas'
                                value={child.grade ?? ''}
                                options={Constant.grade}
                                onChange={(value) => onChange(index, 'grade', value)}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    <Card className='mb-2'>
                        <Card.Body>
                            <FormCheck
                                required
                                label='Jenis Kelamin'
                                value={child.gender ?? ''}
                                options={Constant.gender}
                                onChange={(value) => onChange(index, 'gender', value)}
                            />
                            <span className="separator" />
                            <Form.Control.Feedback type="invalid">
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Card.Body>
                    </Card>

                    {!props.isOutsiteOfChurch &&
                        <>
                            <Card className='mb-2'>
                                <Card.Body>
                                    <Card.Text>No Handphone Anak (*jika ada)</Card.Text>
                                    <Form.Control
                                        type='number'
                                        value={child.phone ?? ''}
                                        onChange={(e) => onChange(index, 'phone', e.target.value)}
                                    />
                                    <span className="separator" />
                                    <Form.Control.Feedback type="invalid">
                                        Pertanyaan wajib diisi
                                    </Form.Control.Feedback>
                                </Card.Body>
                            </Card>

                            <Card className='mb-2'>
                                <Card.Body>
                                    <FormCheck
                                        required
                                        label='Sudah Join Connect Group Eaglekidz?'
                                        value={child.is_join_cg ?? ''}
                                        options={Constant.is_join_cg}
                                        onChange={(value) => onChange(index, 'is_join_cg', value)}
                                    />
                                    <span className="separator" />
                                    <Form.Control.Feedback type="invalid">
                                        Pertanyaan wajib diisi
                                    </Form.Control.Feedback>
                                </Card.Body>
                            </Card>

                            {child.is_join_cg?.toLowerCase?.() === 'y' ? (
                                <Card className='mb-2'>
                                    <Card.Body>
                                        <Card.Text>No Kode Connect Group <Required /></Card.Text>
                                        <Form.Control
                                            required
                                            type='cg_number'
                                            value={child.cg_number ?? ''}
                                            onChange={(e) => onChange(index, 'cg_number', e.target.value?.toUpperCase?.())}
                                        />
                                        <span className="separator" />
                                        <Form.Control.Feedback type="invalid">
                                            Pertanyaan wajib diisi
                                        </Form.Control.Feedback>
                                    </Card.Body>
                                </Card>
                            ) : null}
                        </>
                    }
                </React.Fragment>
            ))}
        </>
    )
}