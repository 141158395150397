import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Registration from "components/pages/registration";
import Transaction from "components/pages/transaction";
import TransactionDetail from "components/pages/transaction-detail";
import Report from "components/pages/report";
import AuthProvider from 'utils/auth-provider';
import 'styles/main.scss';

const packages = require('../package.json');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path='/' element={<Registration />} />
        <Route path='/transaction' element={<Transaction />} />
        <Route path='/transaction/:category' element={<TransactionDetail />} />
        <Route path='/report' element={<Report />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </AuthProvider>
    <span className='version_tag'>{packages.version}</span>
  </BrowserRouter>
);
