import React, { useState } from 'react';
import ReactCollapse from 'react-bootstrap/Collapse';

type CollapseProps = {
    id: string;
    label: React.ReactElement;
    children: React.ReactElement;
    className?: string;
    defaultOpen?: boolean;
}

export const Collapse = (props: CollapseProps) => {
    const [open, setOpen] = useState(props.defaultOpen ?? false);

    const toggle = () => {
        setOpen(prev => !prev);
    }

    return (
        <div className={`mycollapse ${props.className ?? ''}`}>
            <div
                className='mycollapse_toggle'
                onClick={toggle}
                aria-controls={props.id}
                aria-expanded={open}
            >
                {props.label}
            </div>
            <ReactCollapse in={open}>
                <div id={props.id}>
                    {props.children}
                </div>
            </ReactCollapse>
        </div>
    )
}