export const formatDate = (date: string) => {
    return date ? new Date(date)
        .toLocaleDateString('id-ID', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
    }) : '';
}

export const getDate = (date: string | Date) => {
    return date ? new Date(date)
        .toLocaleDateString('id-ID', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour12: true,
    }) : '';
}

export const getTime = (date: string) => {
    return date ? new Date(date)
        .toLocaleTimeString('id-ID', {
            hour: '2-digit',
            minute: '2-digit',
    }) : '';
}

export const formatAmount = (amount: number) => {
    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });
    return formatter.format(amount);
}