import axios from 'axios';
import { useAuth } from '../stores/auth';
import { hash } from '../utils/chiper';
import { Profile } from '../constants/auth';

const baseUrl = process.env.REACT_APP_API_URL_GMS;
const baseUrlBibleStudy = 'https://api-bible-study-jkt.gms.church';

export const getProfile = async (authCode: string): Promise<Profile | null> => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/me`,
            headers: {
                Authorization: `Bearer ${authCode}`,
            }
        });
        const data = response?.data?.result;
        if (data?.mygms_id) {
            const { setProfile } = useAuth.getState();
            const profile = {
                person_id: data.mygms_id,
                name: data.name,
                nij: data.nij,
                phone: data.cell_phone?.number,
                email: data.email?.address,
                address: data.address,
                domicile: data.city?.name
            };
            setProfile(profile);
            return profile;
        }

        throw response;
    } catch (err) {
        return null;
    }
}

export const getAdminToken = async (username: string, password: string) => {
    try {
        const setToken = useAuth.getState().setToken;
        const hashedPassword = hash(password);
        const response = await axios({
            method: 'post',
            url: `${baseUrlBibleStudy}/auth/login`,
            data: {
                username,
                password: hashedPassword,
            }
        });
        const token = response?.data?.result?.token;
        if (token) {
            setToken(token);
            return token;
        }
        throw null;
    } catch (err) {
        return null;
    }
}