import React from 'react';
import Button from 'react-bootstrap/Button';
import { Partnership, PARTNERSHIP_CATEGORY } from 'constants/registration';
import type { ExpanderComponentProps } from 'react-data-table-component';

type Data = Partnership & { nominal: number };

export const ReportPartnershipExpanded: React.FC<ExpanderComponentProps<Data>> = ({ data }) => (
    <div className="d-flex gap-4 report_children">
        <div className='d-flex flex-column gap-2'>
            {data.payment_confirmation ? (
                <>
                    <img className='report_children_img' src={`data:image/jpeg;base64,${data.payment_confirmation}`} />
                    <Button size='sm' href={`data:application/octet-stream;base64,${data.payment_confirmation}`}>Unduh</Button>
                </>
            ) : (
                <div className='report_children_img'></div>
            )}
        </div>
        <div className='report_children_partnership'>
            <span className='mb-3 fw-bold text-uppercase'>Rincian Transaksi</span>
            <div className='report_children_payment mb-4'>
                <div>
                    <span className='fw-bold'>No Handphone Jemaat</span>
                    <span>{data.phone || '-'}</span>
                </div>

                {data.partnership_category?.toUpperCase?.() === PARTNERSHIP_CATEGORY.DANA ? (
                    <>
                        <div>
                            <span className='fw-bold'>Jumlah Anak yang Disponsori</span>
                            <span>{data.sponsored_children_count || '-'}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <span className='fw-bold'>Alamat Domisili</span>
                            <span>{data.domicile || '-'}</span>
                        </div>
                        <div>
                            <span className='fw-bold'>Plat Nomor</span>
                            <span>{data.number_plate || '-'}</span>
                        </div>
                        <div>
                            <span className='fw-bold'>Jenis Mobil</span>
                            <span>{data.car_type || '-'}</span>
                        </div>
                        <div>
                            <span className='fw-bold'>Warna Mobil</span>
                            <span>{data.car_color || '-'}</span>
                        </div>
                        <div>
                            <span className='fw-bold'>Muatan</span>
                            <span>{data.car_load || '-'}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    </div>
)