import { useNavigate } from "react-router-dom"
import Placeholder from "components/atoms/placeholder";
import { getDate, getTime } from "utils/format";
import { useCallback, useEffect, useMemo } from "react";
import { DataRegistration, RegistrationCategory } from "constants/registration";
import { getRegistrationByPersonId } from "datas/registration";
import { useAuth } from "stores/auth";
import { useRegistration } from "stores/registration";

export const Transaction = () => {
    const dataAutoReloadInterval = 30000;

    const navigate = useNavigate();
    const personId = useAuth(state => state.profile?.person_id);
    const dataRegistration = useRegistration(state => state.dataRegistration);
    const setDataRegistration = useRegistration(state => state.setDataRegistration);

    const onNavigateToTransactionDetail = (category: RegistrationCategory, bill_no: string) => {
        navigate(`/transaction/${category}?reg=${bill_no}`);
    }

    const onNavigateToRegistrationForm = () => {
        navigate('/');
    }

    const handleLoadDataRegistration = useCallback(async () => {
        try {
            if (!personId) {
                throw new Error('Person ID not found.');
            }
    
            const data = await getRegistrationByPersonId(personId);
            if (data) {
                setDataRegistration(() => {
                    return Object.entries(data).flatMap(([key, items]) =>
                        items.map((item: any) => ({
                            ...item,
                            category: key
                        }))
                    );
                });
            } else {
                throw new Error('Data not found');
            }
        } catch (err) {
            console.error(err);
        }
    }, [personId])
    
    const getCategoryColor = (status: RegistrationCategory) => {
        let variant = 'success';

        if (status === 'school') variant = 'warning';
        else if (status === 'partnership') variant = 'info';

        return variant;
    }

    useEffect(() => {
        handleLoadDataRegistration();
        const autoReloadInterval = setInterval(
            handleLoadDataRegistration,
            dataAutoReloadInterval
        );

        return () => {
            clearInterval(autoReloadInterval);
        };
    }, [])

    const data = useMemo(() => {
        if (!dataRegistration) return null;

        const sortedData = dataRegistration.sort((a, b) => 
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
    
        const groupedData = sortedData.reduce((acc, item) => {
            const date = new Date(item.created_at);
            const dateKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, {} as Record<string, DataRegistration[]>);
    
        return {
            data: groupedData,
            length: dataRegistration?.length
        };
    }, [dataRegistration])

    return (
        <section className='transaction_container'>
            <div className='transaction_header'>
                <h6 className="mb-0 fw-bold">Riwayat pendaftaran</h6>
            </div>

            <div className="transaction_cover" role="button" onClick={onNavigateToRegistrationForm}>
                <img src='/bible-teaching.webp' />
                <div className="transaction_cover_label">
                    <h6>Yuk daftar Bible Study!</h6>
                    <div className="transaction_cover_label_description">
                        <p>Jangan sampai ketinggalan, pendaftaran dibuka 24 Agustus - 20 September 2024</p>
                        <span className="transaction_cover_button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                        </span>
                    </div>
                </div>
            </div>

            <div className="transaction_content">
                {data ? 
                    data.length ? Object.keys(data.data).map(itemDate => (
                        <div key={`list-of-registration-${itemDate}`}>
                            <span className="transaction_content_label">
                                {getDate(data.data[itemDate][0]?.created_at)}
                            </span>
                            <div className="transaction_content_card">
                                {data.data[itemDate].map(item => (
                                    <div
                                        className="transaction_content_item py-3"
                                        onClick={() => onNavigateToTransactionDetail(item.category, item.bill_no ?? item.registration_number)}
                                        key={item.id}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <small className="fw-bold text-uppercase">
                                                {item.parent_name ?? item.teacher_name ?? item.name}
                                            </small>
                                            <small className={`fw-bold text-uppercase text-${getCategoryColor(item.category)}`} style={{ letterSpacing: 1 }}>
                                                {item.category === 'parent' 
                                                    ? 'individu'
                                                    : item.category === 'school'
                                                        ? 'kelompok'
                                                        : item.category
                                                    }
                                            </small>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <small>{item.bill_no ?? item.registration_number}</small>
                                            <small>{getTime(item.created_at)}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )) : (
                        <div className="d-flex align-items-center justify-content-center py-5">
                            <p>Tidak ada riwayat pendaftaran</p>
                        </div>
                    ) : Array.from({ length: 3 }, (_, index) => 0 + index).map((index) => (
                        <div className="transaction_content_card mt-4" key={`transaction_content_card_${index}`}>
                            <div className="transaction_content_item py-3" key={`placeholder_${index}`}>
                                <div className="d-flex justify-content-between">
                                    <Placeholder width={160} height={19.5} />
                                    <Placeholder width={90} height={19.5} />
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                    <Placeholder width={90} height={19.5} />
                                    <Placeholder width={70} height={19.5} />
                                </div>
                            </div>

                            <div className="transaction_content_item py-3" key={`placeholder_2_${index}`}>
                                <div className="d-flex justify-content-between">
                                    <Placeholder width={160} height={19.5} />
                                    <Placeholder width={90} height={19.5} />
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                    <Placeholder width={90} height={19.5} />
                                    <Placeholder width={70} height={19.5} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}