import React, { useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Collapse from 'components/molecules/collapse';
import * as Constant from 'constants/registration';
import type { Child, Group } from 'constants/registration';
import { getRegistrationByBillNo } from 'datas/registration';
import PaymentInformationGroup from 'components/atoms/payment-information-group';

type PreviewGroupProps = {
    registrationNumber: string;
}

export const PreviewGroup = (props: PreviewGroupProps) => {
    const [dataRegistration, setDataRegistration] = useState<Group>();

    const columns: { key: keyof Group, label: string }[] = [
        { key: 'school_name', label: 'Nama Sekolah/Rombongan' },
        { key: 'teacher_name', label: 'Nama Pendamping/Guru' },
        { key: 'teacher_phone', label: 'No Handphone Pendamping' },
        { key: 'teacher_count', label: 'Jumlah Pendamping/Guru' },
        { key: 'student_count', label: 'Jumlah Anak yang didaftarkan' },
        { key: 'school_domicile', label: 'Domisili' },
        { key: 'event_location', label: 'Akan mengikuti Bible Study di' }
    ];

    const childColumns: { key: keyof Child, label: string }[] = [
        { key: 'grade', label: 'Grade Anak' },
        { key: 'gender', label: 'Gender Anak' },
        { key: 'phone', label: 'No HP Anak' },
    ];

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(props.registrationNumber)
            .then(() => {
                alert('Berhasil menyalin No Registrasi');
            });
    }

    const onLoadDataRegistration = async () => {
        try {
            if (!props.registrationNumber) throw 'Registration number no doesnt exist';

            const data = await getRegistrationByBillNo({
                regNo: props.registrationNumber,
                singleSnapshot: true
            });
            if (data) {
                setDataRegistration(data);
            }

            throw 'Data not found';
        } catch (err) {
            console.error(err);
        }
    }

    const children = useMemo(() => {
        if (!dataRegistration?.student?.length) return [];

        return dataRegistration?.student.map((item) => {
            let gender = item.gender;
            const selectedGender = Constant.gender.find(({ value }) => value === item.gender);
            if (selectedGender) gender = selectedGender.label;
            return {
                ...item,
                gender
            }
        })
    }, [dataRegistration?.student])

    useEffect(() => {
        onLoadDataRegistration();
    }, [])

    return dataRegistration ? (
        <div className='preview preview-group pb-0'>
            <Alert>
                <small className='text-muted'>No Registrasi Anda:</small>
                <span className='fw-bold'><u>{props.registrationNumber}</u></span>
                <Button size='sm' onClick={handleCopyToClipboard}>
                    <small>SALIN</small>
                </Button>
            </Alert>

            <hr />

            <div className='preview_item'>
                <span className='preview_item_value'>
                    Data Pendaftaran Anda:
                </span>
            </div>

            {columns.map(({ key, label }) => (
                <div className='preview_item' key={`preview_individual_parent_${key}`}>
                    <small className='text-muted'>{label}</small>
                    <span className='preview_item_value'>
                        {key === 'student_count' ? dataRegistration.student.length : dataRegistration[key] as string}
                    </span>
                </div>
            ))}

            {children?.map((child, index) => (
                <Collapse
                    key={`preview_individual_child_${child.name.toLowerCase()}_${index}`}
                    id={`preview_individual_child_${child.name.toLowerCase()}_${index}`}
                    defaultOpen={index < 1}
                    className='preview_collapse'
                    label={
                        <div className='preview_item'>
                            <small className='text-muted text-capitalize'>
                                Nama Lengkap Anak {Constant.numberDescriptions[index + 1] ?? index + 1}
                            </small>
                            <span className='preview_item_value'>{child.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/>
                            </svg>
                        </div>
                    }
                >
                    <React.Fragment>
                        {childColumns.map(({ key, label }) => (
                            <div className='preview_item' key={`preview_item_${key}`}>
                                <small className='text-muted'>{label}</small>
                                <span className='preview_item_value'>{child[key] as string || '-'}</span>
                            </div>
                        ))}
                    </React.Fragment>
                </Collapse>
            ))}

            <hr />

            <PaymentInformationGroup />
        </div>
    ) : null
}