import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Required from 'components/atoms/form-required';
import FormChildren from '../form-children';
import { useRegistration } from 'stores/registration';
import { useAuth } from 'stores/auth';
import * as Constant from 'constants/registration';
import FormCheck from 'components/atoms/form-check';

export const FormIndividual = () => {
    const profile = useAuth().profile;
    const {
        payloadIndividual: payload,
        setPayloadIndividual: setPayload,
        payloadChildren,
        setPayloadChildren
    } = useRegistration();

    const onChange = (key: keyof Constant.PayloadIndividual, value: string) => {
        const newPayload = payload ? {...payload} : {} as Constant.PayloadIndividual;
        newPayload[key] = value as never;
        setPayload(newPayload);
    }

    const onChangeChildren = <K extends keyof Constant.Child>(
        key: number,
        keyField: K,
        value: Constant.Child[K]
    ) => {
        const newPayload: Constant.Child[] = [...payloadChildren];
        newPayload[key][keyField] = value;
        setPayloadChildren(newPayload);
    };

    const handleChildrenAction = (prop: {
        action: 'add' | 'remove',
        removedId?: number,
        children?: Constant.Child,
    }) => {
        const { action, removedId, children } = prop;
        const newPayload = [...payloadChildren];
        if (action === 'add') {
            newPayload.push(children as Constant.Child);
            setPayloadChildren(newPayload);
        } else if (action === 'remove' && removedId != undefined) {
            newPayload.splice(removedId, 1);
            setPayloadChildren(newPayload);
        }
    }

    useEffect(() => {
        if (profile?.person_id) {
            const newPayload = {...payload};
            newPayload.parent_name = profile.name || '';
            newPayload.parent_phone = profile.phone || '';
            newPayload.parent_email = profile.email || '';
            newPayload.parent_address = profile.address || '';
            
            if (profile.domicile) {
                const domicile = profile.domicile.toUpperCase();
                const selectedDomicile = Constant.domicile_address.find(({ value }) => value === domicile);
                if (selectedDomicile) {
                    newPayload.parent_domicile = selectedDomicile.value;
                } else {
                    newPayload.parent_domicile = 'OTHERS';
                    newPayload.parent_domicile_other = domicile;
                }
            }
            setPayload(newPayload as unknown as Constant.PayloadIndividual);
        }
    }, [])

    return (
        <Form.Group>
            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Nama lengkap Orang Tua/Wali <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.parent_name ?? ''}
                        onChange={(e) => onChange('parent_name', e.target.value?.toUpperCase?.())}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>No Handphone Orang Tua/Wali <Required /></Card.Text>
                    <Form.Control
                        required
                        type='number'
                        value={payload?.parent_phone ?? ''}
                        onChange={(e) => onChange('parent_phone', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Email Orang Tua/Wali <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.parent_email ?? ''}
                        onChange={(e) => onChange('parent_email', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Kota Domisili <Required /></Card.Text>
                    <Form.Select
                        required
                        value={payload?.parent_domicile ?? ''}
                        onChange={(e) => onChange('parent_domicile', e.target.value)}
                    >
                        <option hidden value=''>Pilih kota domisili</option>
                        {Constant.domicile_address.map(option => (
                            <option value={option.value} key={`domicile_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            {payload?.parent_domicile?.toLowerCase?.() === 'others' ? (
                <Card className='mb-2'>
                    <Card.Body>
                        <Card.Text>Kota Domisili Lainnya  <Required /></Card.Text>
                        <Form.Control
                            required={payload?.parent_domicile.toLowerCase() === 'others'}
                            value={payload?.parent_domicile_other ?? ''}
                            onChange={(e) => onChange('parent_domicile_other', e.target.value?.toUpperCase?.())}
                        />
                        <span className="separator" />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Card.Body>
                </Card>
            ) : null}

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Mengikuti Bible Study 2024 di Gereja Lokal? <Required /></Card.Text>
                    <Form.Select
                        required
                        value={payload?.event_location ?? ''}
                        onChange={(e) => onChange('event_location', e.target.value)}
                    >
                        <option hidden value=''>Pilih gereja lokal</option>
                        {Constant.event_location.map(option => (
                            <option value={option.value} key={`location_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <FormCheck
                        required
                        label='Penjemputan Anak'
                        value={payload?.pick_up_type ?? ''}
                        options={Constant.child_pick_up}
                        onChange={(value) => onChange('pick_up_type', value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <FormChildren
                data={payloadChildren}
                onChange={onChangeChildren}
                onChangeRow={handleChildrenAction}
            />

            <div className='child_form_action'>
                <span>
                    Ingin menambah data untuk anak {Constant.numberDescriptions[payloadChildren.length + 1]}?
                </span>
                <Button
                    size='sm'
                    variant='secondary'
                    onClick={() => handleChildrenAction({
                        action: 'add',
                        children: new Constant.Child(),
                    })}
                >
                    Tambah
                </Button>
            </div>
        </Form.Group>
    )
}