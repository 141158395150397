import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAuth } from 'stores/auth';
import { getAdminToken } from 'datas/auth';
import { useTransaction } from 'stores/transaction';
import { getAllPaymentTransaction } from 'datas/transaction';
import ReportIndividual from 'components/organisms/reports/report-individual';
import ReportGroup from 'components/organisms/reports/report-group';
import ReportPartnership from 'components/organisms/reports/report-partnership';
import { TransactionPayment } from 'constants/transaction';

enum TabRefs {
    Individual = 'Individual',
    Group = 'Group',
    Partnership = 'Partnership'
}

export const Report = () => {
    const auth = useAuth();
    const setAllPaymentTransaction = useTransaction().setAllPaymentTransaction;

    const getTransactionPayment = async (page?: number, existedData: TransactionPayment[] = []) => {
        const pagination = page ?? 1;
        
        const response = await getAllPaymentTransaction(pagination, 2000);
        const newData = existedData?.concat(response);
        if (response?.length) {
            getTransactionPayment(pagination + 1, newData);
        } else {
            setAllPaymentTransaction(newData);
        }
    }

    const checkAuth = async () => {
        if (!auth.profile?.person_id) return;

        if (!auth.token) {
            const username = prompt('Username');
            const password = prompt('Password');

            if (username && password) {
                const token = await getAdminToken(username, password);
                if (token) {
                    getTransactionPayment();
                    return true;
                }
            }

            alert('Kredensial tidak valid');
            checkAuth();
        }
    }

    useEffect(() => {
        checkAuth();
    }, [auth.profile?.person_id])

    return auth.token ? (
        <div className='report_container'>
            <Tabs
                defaultActiveKey={TabRefs.Individual}
                transition={false}
                id='report_tabs'
            >
                <Tab eventKey={TabRefs.Individual} title={TabRefs.Individual}>
                    <ReportIndividual />
                </Tab>
                <Tab eventKey={TabRefs.Group} title={TabRefs.Group}>
                    <ReportGroup />
                </Tab>
                <Tab eventKey={TabRefs.Partnership} title={TabRefs.Partnership}>
                    <ReportPartnership />
                </Tab>
            </Tabs>
        </div>
    ) : null;
}