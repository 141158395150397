import React, { useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { CSVLink } from 'react-csv';
import Form from 'react-bootstrap/Form';
import { Child, event_location, Group } from 'constants/registration';
import { formatDate } from 'utils/format';
import { getAllGroupRegistration } from 'datas/registration';
import ReportGroupExpanded from '../report-group-expanded';

type Data = Child & Group & { school: Group };

export const ReportGroup = () => {
    const dataAutoReloadInterval = 30000;

    const [dataRegistration, setDataRegistration] = useState<Data[]>();
    const [filterName, setFilterName] = useState('');
    const [filterEventLocation, setFilterEventLocation] = useState('');

    const headers = [
        { label: 'No Registrasi', key: 'registration_number' },
        { label: 'Nama Sekolah/Rombongan', key: 'school_name' },
        { label: 'Email Sekolah/Rombongan', key: 'school_email' },
        { label: 'Nama Pendamping/Guru', key: 'teacher_name' },
        { label: 'No Handphone Pendamping/Guru', key: 'teacher_phone' },
        { label: 'Jumlah Pendamping/Guru', key: 'teacher_count' },
        { label: 'Domisili Sekolah', key: 'school_domicile' },
        { label: 'Akan mengikuti Bible Study di', key: 'event_location' },
        { label: 'Nama Lengkap Anak', key: 'name' },
        { label: 'Kelas', key: 'grade' },
        { label: 'Jenis Kelamin Anak', key: 'gender' },
        { label: 'Tanggal Registrasi', key: 'created_at' }
    ];

    const columns: TableColumn<Data>[] = [
        {
            id: 'registration_number',
            name: 'No Registrasi',
            selector: row => row?.school?.registration_number ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'name',
            name: 'Nama Lengkap Anak',
            selector: row => row?.name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'school_name',
            name: 'Nama Sekolah/Rombongan',
            selector: row => row?.school?.school_name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'teacher_name',
            name: 'Nama Pendamping/Guru',
            selector: row => row?.school?.teacher_name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'event_location',
            name: 'Lokasi Acara',
            selector: row => row?.school?.event_location ?? '',
            sortable: true
        },
        {
            id: 'created_at',
            name: 'Tanggal Registrasi',
            selector: row => row?.school?.created_at ? formatDate(row.school.created_at) : '',
            sortable: true,
        }
    ];

    const handleLoadDataRegistration = async () => {
        try {
            const response = await getAllGroupRegistration();
            if (response) {
                const data = response.map(item => ({
                    ...item.school,
                    ...item,
                }))
                setDataRegistration(data);
                return true;
            }

            throw response;
        } catch (err) {
        }
    }

    const data = useMemo<Data[]>(() => {
        if (!dataRegistration) return [];
    
        const matchesFilters = (item: Data) => {
            const { school, name, registration_number } = item;
    
            if (filterEventLocation && school?.event_location !== filterEventLocation) return false;
            if (filterName) {
                const regNoMatch = registration_number?.toUpperCase().includes(filterName.toUpperCase());
                const schoolNameMatch = school?.school_name?.toLowerCase().includes(filterName.toLowerCase());
                const teacherNameMatch = school?.teacher_name?.toLowerCase().includes(filterName.toLowerCase());
                const childNameMatch = name?.toLowerCase().includes(filterName.toLowerCase());
                if (!regNoMatch && !schoolNameMatch && !teacherNameMatch && !childNameMatch) return false;
            }
    
            return true;
        };
    
        return dataRegistration
            .filter(matchesFilters);
    }, [dataRegistration, filterEventLocation, filterName]);   

    useEffect(() => {
        handleLoadDataRegistration();
        const autoReloadInterval = setInterval(
            handleLoadDataRegistration,
            dataAutoReloadInterval
        );

        return () => {
            clearInterval(autoReloadInterval);
        }
    }, []);

    return dataRegistration ? (
        <div className='report_container_content'>
            <div className='report_filter'>
                <Form.Control
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder='Cari Nama atau No Registrasi'
                />
                <Form.Select
                    value={filterEventLocation}
                    onChange={(e) => {
                        setFilterEventLocation(e.target.value)
                    }}
                >
                    <option value=''>Cari Lokasi Acara</option>
                    {event_location.map(location => (
                        <option value={location.value} key={`option_event_location_${location.value}`}>
                            {location.label}
                        </option>
                    ))}
                </Form.Select>

                <Button variant="success">
                    <CSVLink
                        data={data}
                        headers={headers}
                        filename='transaction_bible_study_report'
                        target="_blank"
                    />
                    Export Data
                </Button>
            </div>
            <DataTable
                keyField='id'
                data={data}
                columns={columns}
                pagination
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={ReportGroupExpanded}
            />
        </div>
    ) : (
        <div className='d-flex align-items-center justify-content-center' style={{ height: 'calc(100vh - 10rem)' }}>
            <Spinner />
        </div>
    )
}