import React from 'react';
import { Child, Group } from 'constants/registration';
import { ExpanderComponentProps } from 'react-data-table-component';

type Data = Child & Group & { school: Group };

export const ReportGroupExpanded: React.FC<ExpanderComponentProps<Data>> = ({ data }) => {
    return (
        <div className="report_children">
            <span className='mb-2 fw-bold text-uppercase'>Rincian Transaksi</span>
            <div className='report_children_payment mb-4'>
                <div>
                    <span className='fw-bold'>Kelas</span>
                    <span className='fw-bold'>{data.grade || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>Jenis Kelamin Anak</span>
                    <span className='fw-bold'>{data.gender || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>Email Sekolah</span>
                    <span className='fw-bold'>{data.school_email || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>Nama Pendamping/Guru</span>
                    <span className='fw-bold'>{data.teacher_name || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>Jumlah Pendamping/Guru</span>
                    <span className='fw-bold'>{data.teacher_count || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>No Handphone Pendamping/Guru</span>
                    <span className='fw-bold'>{data.teacher_phone || '-'}</span>
                </div>
                <div>
                    <span className='fw-bold'>Domisili Sekolah</span>
                    <span className='fw-bold'>{data.school_domicile || '-'}</span>
                </div>
            </div>
        </div>
    )
}; 