import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Partnership, payment_branch } from 'constants/registration';
import { formatAmount } from 'utils/format';
import { getRegistrationByBillNo } from 'datas/registration';
import { useRegistration } from 'stores/registration';
import { ticketPrice } from 'constants/transaction';

type FormPartnershipPaymentProps = {
    registrationNumber: string;
}

export const FormPartnershipPayment = (props: FormPartnershipPaymentProps) => {
    const { setPartnershipPaymentImage: setPaymentImage } = useRegistration();
    const inputFile = useRef<HTMLInputElement | null>(null);

    const [dataRegistration, setDataRegistration] = useState<Partnership>();
    const [paymentImageString, setPaymentImageString] = useState('');

    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };

    const onSelectPaymentConfirmation = () => {
        inputFile.current?.click();
    }

    const onUploadPaymentConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0] ? e.target.files[0] : '';
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPaymentImageString(fileUrl);
            setPaymentImage(file);
        }
        handleReset();
    }

    const onLoadDataRegistration = async () => {
        try {
            if (!props.registrationNumber) throw 'Registration number no doesnt exist';

            const data = await getRegistrationByBillNo({
                regNo: props.registrationNumber,
                singleSnapshot: true
            });
            if (data) {
                setDataRegistration(data);
            }

            throw 'Data not found';
        } catch (err) {
            console.error(err);
        }
    }

    const nominal = useMemo(() => {
        if (!dataRegistration?.sponsored_children_count) return '-';
        return formatAmount(dataRegistration.sponsored_children_count * ticketPrice)
    }, [dataRegistration?.sponsored_children_count])

    const paymentBranch = useMemo(() => {
        if (!dataRegistration?.event_location) return null;

        return payment_branch.find(
            ({ value }) => value.toLowerCase?.() === dataRegistration?.event_location?.toLowerCase?.());
    }, [dataRegistration?.event_location])

    useEffect(() => {
        onLoadDataRegistration();
    }, [props.registrationNumber])

    return (
        <div className='preview preview-group'>
            <div className='preview_info'>
                <span className='mb-3 text-justify'>
                    Silakan melakukan pembayaran sebesar {nominal} ke rekening:
                </span>
                <div className='my-3 text-center fw-bold text-justify'>
                    <span>{paymentBranch?.value}</span>
                    <span>{paymentBranch?.bank}</span>
                    <span>{paymentBranch?.bank_no}</span>
                    <span>{paymentBranch?.desc}</span>
                </div>
                <span className='mb-3 text-justify'>
                    Setelah berhasil melakukan pembayaran, silakan unggah bukti pembayaran pada form berikut:
                </span>

                {paymentImageString ? (
                    <div className='preview_info_image_preview'>
                        <img className='report_children_img w-100 py-2' src={paymentImageString}  />
                    </div>
                ) : null}

                <div className='d-flex flex-column align-items-center'>
                    <Button className='mb-3' onClick={onSelectPaymentConfirmation}>
                        Unggah {paymentImageString ? 'Ulang' : ''} Bukti Pembayaran
                    </Button>
                    <input ref={inputFile} type='file' onChange={onUploadPaymentConfirmation} style={{ display: 'none' }} />
                </div>
            </div>
        </div>
    )
}